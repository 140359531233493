// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;

@import "@zonar-ui/sidenav/zonar-ui-sidenav.theme.scss";
@import "@zonar-ui/notifications/zonar-ui-notifications.theme.scss";
@import "@zonar-ui/material/zonar-ui-material.theme.scss";
@import "material-icons/iconfont/material-icons.scss";
@import "../app/shared/components/detail-list/detail-list.theme.scss";
@import "../app/shared/components/page-not-found/page-not-found.theme.scss";
@import "../styles/common.theme.scss";
@import "../app/app.component.theme.scss";
/* shared components theme */
/* pages theme */

// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@mixin apply-zonar-theme($theme) {
  @include mat.all-component-themes($theme);
  @include zonar-ui-sidenav-theme($theme);
  @include zonar-ui-notifications-theme($theme);

  @include detail-list-theme($theme);
  @include page-not-found-theme($theme);
  @include app-theme($theme);
  @include common-theme($theme);
}

.zonar-default-theme {
  @include apply-zonar-theme($zonar-default-theme);
  background-color: #f3f4f5;
}

.zonar-conti-theme {
  @include apply-zonar-theme($zonar-conti-theme);
  background-color: #f0f0f0;
}

.zonar-dark-theme {
  @include apply-zonar-theme($zonar-dark-theme);
  background-color: black;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.mat-raised-button,
.mat-stroked-button,
.mat-flat-button,
.mat-button-base,
.mat-button-wrapper {
  padding: 0;
  margin: 0;
  line-height: unset;
}