@mixin common-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-user-select,
  app-edit-user,
  app-create-user,
  app-token-dialog,
  app-api-credentials {
    .title-page .title {
      @include mat.typography-level($zonar-default-typography, title);
    }
  }

  .mat-primary .mat-pseudo-checkbox-checked {
    background: mat.get-color-from-palette($background, flat-button);
  }

  .mat-focused .mat-form-field-required-marker,
  .mat-form-field-required-marker {
    color: $critical;
  }

  .mat-flat-button {
    background: mat.get-color-from-palette($background, flat-button);
    color: mat.get-color-from-palette($foreground, sidenav-fg);
  }

  .mat-stroked-button:not(.mat-button-disabled) {
    background-color: transparent;
    color: mat.get-color-from-palette($foreground, text-stroked-button);
    border-color: mat.get-color-from-palette($background, border-stroked-button);
  }
}