@use "@angular/material" as mat;

@mixin app-theme($theme) {
  $background: map-get($theme, background);
  $foreground: map-get($theme, foreground);

  app-root {
    .mat-progress-spinner circle,
    .mat-spinner circle {
      stroke: mat.get-color-from-palette($background, flat-button);
    }
  }
}
