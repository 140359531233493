@use "@angular/material" as mat;

@mixin detail-list-theme($theme) {
  $background: map-get($theme, background);

  app-detail-list {
    font-size: 0.876rem;
  
    .mat-grid-tile {
      border: solid 0.063rem mat.get-color-from-palette($background, border-stroked-button);
    }
  
    .mat-grid-tile:nth-child(even){
      background-color: mat.get-color-from-palette($background, raised-button);
    }
          
    .mat-grid-tile:nth-child(odd){
      background-color: mat.get-color-from-palette($background, stroked-button);
    }
  }
}